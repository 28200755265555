<template>
  <fragment>
    <v-select
      v-if="baseSelectField"
      :value="value"
      :items="items"
      :label="label"
      :required="required"
      @input="$emit('input', $event)"
      dense
    >
      <!-- КАСТОМИЗАЦИЯ ПОЛЕЙ -->
      <template v-slot:selection="{ item }">
        <div class="baseSelectField">{{ item.text }}</div>
      </template>

      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title>
              <div class="baseSelectField">{{ item.text }}</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- /КАСТОМИЗАЦИЯ ПОЛЕЙ -->
    </v-select>

    <v-autocomplete
      v-else
      :auto-select-first="autoSelectFirst"
      :label="label"
      :loading="isLoading"
      :disabled="disabled"
      :mask="mask"
      :type="type"
      :search-input="searchValue"
      :error-messages="errors"
      @update:search-input="updateSearchValue($event)"
      :value="value"
      @focus="asyncSearch('')"
      @input="$emit('input', $event)"
      :return-object="returnObject"
      :required="required"
      no-filter
      :counter="counter"
      :hide-selected="hideSelected"
      :hide-no-data="false"
      @click:clear="asyncSearch('')"
      no-data-text="Нет результатов"
      :multiple="multiple"
      clearable
      :items="items"
    >
      <template v-if="$scopedSlots.selection" v-slot:selection="{ item }">
        <slot name="selection" v-bind:item="item"></slot>
      </template>
    </v-autocomplete>
  </fragment>
  <!--    :filter="() => true"-->
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "AutocompleteField",
  props: {
    hideItems: {
      type: Boolean,
      default: false
    },
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {
      type: [String, Object, Number, Array]
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    searchFunction: {
      type: Function
    },
    baseSelectField: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  mounted() {
    this.debounceSearch("");
  },
  data() {
    return {
      searchValue: null,
      isLoading: false,
      items: [],
      select: null,
      oldSearch: null
    };
  },
  methods: {
    updateSearchValue(val) {
      if (val != null && val !== "" && val !== this.oldSearch) {
        this.$emit("update:search-input", val);
        this.asyncSearch(val || "");
        this.oldSearch = val;
      }
    },
    debounceSearch(value) {
      this.isLoading = true;

      // Lazily load input items
      this.searchFunction(value)
        .then(res => (this.items = res))
        .finally(() => (this.isLoading = false));
    },
    asyncSearch: debounce(function(value) {
      this.debounceSearch(value);
    }, 1000)
  }
};
</script>

<style></style>
