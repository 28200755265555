<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <div class="test"></div>
    <FileField
      :is-doc="isDoc"
      :value="value"
      :disabled="disabled"
      :type="type"
      :accept="accept"
      :label="label"
      :errors="errors"
      :required="required"
      @change="$emit('change', $event)"
      :counter="counter"
    ></FileField>
  </ValidationProvider>
</template>

<script>
import FileField from "@/components/fields/FileField";
export default {
  name: "ValidationFileField",
  components: { FileField },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    isDoc: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: true
    },
    type: {
      type: String
    },
    accept: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {},
    errors: {
      required: false
    },
    validateName: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
