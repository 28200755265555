<template>
  <div class="list__inner list_overflow_visible">
    <div class="switching">
      <a
        v-for="(item, index) in tabs"
        class="switching__item"
        :key="index"
        :class="item.value === tabs[tab].value ? 'active' : ''"
        @click="tab = index"
      >
        <p class="switching__text">{{ item.text }}</p>
        <p
          v-if="!!item && typeof item.count === 'number'"
          class="switching__number"
        >
          {{ item.count }}
        </p>
        <p v-if="item.icon" class="switching__number switching__number_circle">
          {{ item.icon }}
        </p>
      </a>
    </div>
    <div v-for="(item, index) in tabs" :key="item.value">
      <slot v-if="index === tab" :name="item.value">
        {{ item.value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideLayout",
  props: {
    tabs: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      tab: 0
    };
  }
};
</script>

<style scoped></style>
