<template>
  <ValidationObserver
    tag="div"
    :ref="refName"
    class="ym-hide-content"
    v-slot="{ validate, reset, handleSubmit }"
  >
    <form
      @submit.prevent="
        () => {
          return false;
        }
      "
      :class="formClass"
    >
      <slot
        v-bind:validate="validate"
        v-bind:reset="reset"
        v-bind:handleSubmit="handleSubmit"
      ></slot>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "ValidationForm",
  props: {
    refName: {
      type: String
    },
    formClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
