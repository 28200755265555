<template>
  <div class="form-wrap">
    <ValidationForm
      formClass="form card"
      v-slot="{ validate, reset, handleSubmit }"
    >
      <div class="form__avatar form__avatar-company" />

      <div :class="long ? '' : 'inputs'" class="ym-hide-content">
        <slot
          v-bind:validate="validate"
          v-bind:reset="reset"
          v-bind:handleSubmit="handleSubmit"
          name="default"
        >
          <div class="input-field">
            <label>Название</label>
            <input type="text" class="validate" required />
            <span
              class="helper-text"
              data-error="Не заполнено поле"
              data-success="right"
            ></span>
          </div>
          <div class="input-field">
            <label>Город</label>
            <input type="text" class="validate" required />
            <span
              class="helper-text"
              data-error="Не заполнено поле"
              data-success="right"
            ></span>
          </div>
          <div class="input-field">
            <select id="object_1" class="selectm">
              <option value="" disabled selected>Тип организации</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
            <label>Тип организации</label>
          </div>
          <div class="input-field">
            <label>Адрес</label>
            <input type="text" class="validate" required />
            <span
              class="helper-text"
              data-error="Не заполнено поле"
              data-success="right"
            ></span>
          </div>
          <div class="input-field">
            <label>Телефон</label>
            <input type="text" class="validate" required />
            <span
              class="helper-text"
              data-error="Не заполнено поле"
              data-success="right"
            ></span>
          </div>
          <div class="input-field">
            <select id="object_2" class="selectm">
              <option value="" disabled selected>E-mail</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
            <label>E-mail</label>
          </div>
        </slot>
      </div>
      <a
        v-if="isDeletable"
        @click="$emit('delete')"
        class="form__btn-delete"
      ></a>
    </ValidationForm>
  </div>
</template>

<script>
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "FormInputBlock",
  components: { ValidationForm },
  props: {
    long: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
