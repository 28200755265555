<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <AutocompleteField
      hide-items
      :auto-select-first="autoSelectFirst"
      :value="value"
      :searchFunction="searchFunction"
      :disabled="disabled"
      :type="type"
      :mask="mask"
      :return-object="returnObject"
      :label="label"
      :hide-selected="hideSelected"
      :errors="errors"
      :multiple="multiple"
      @update:search-input="$emit('update:search-input', $event)"
      :required="required"
      @input="$emit('input', $event)"
      :counter="counter"
      :baseSelectField="baseSelectField"
    >
      <template v-if="$scopedSlots.selection" v-slot:selection="{ item }">
        <slot name="selection" v-bind:item="item"></slot>
      </template>
    </AutocompleteField>
  </ValidationProvider>
</template>

<script>
import AutocompleteField from "@/components/fields/AutocompleteField";
export default {
  name: "ValidationAutocompleteField",
  components: { AutocompleteField },
  props: {
    hideItems: {
      type: Boolean,
      default: false
    },
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    searchFunction: {
      type: Function
    },
    mask: {
      type: String
    },
    type: {
      type: String
    },
    rules: {
      type: String
    },
    validateName: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Object, Number, Array]
    },
    required: {
      type: Boolean,
      default: false
    },
    baseSelectField: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
