import { render, staticRenderFns } from "./SlideLayout.vue?vue&type=template&id=4485db48&scoped=true&"
import script from "./SlideLayout.vue?vue&type=script&lang=js&"
export * from "./SlideLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4485db48",
  null
  
)

export default component.exports