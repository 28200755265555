<template>
  <div class="data card">
    <slot class="data__text" name="default">
      <p class="data__text">{{ title }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Данные компании"
    }
  },
  name: "FormCardTitle"
};
</script>

<style scoped></style>
