<template>
  <fragment>
    <div class="d-flex flex-row align-center">
      <img
        style="margin-right: 5px"
        v-if="isDoc"
        width="30"
        height="30"
        src="/img/icons/input-file__icon_1.svg"
      />
      <img
        style="margin-right: 5px"
        v-if="isDoc"
        width="30"
        height="30"
        src="/img/icons/input-file__icon_2.svg"
      />
      <v-file-input
        @click.stop="() => false"
        :prepend-inner-icon="!isDoc ? 'mdi-file' : ''"
        prepend-icon=""
        :value="value"
        @change="$emit('change', $event)"
        :error-messages="errors"
        :label="label"
        :accept="accept"
        :disabled="disabled"
        type="file"
        :multiple="multiple"
        :counter="counter"
      />
    </div>
  </fragment>

  <!--    :filter="() => true"-->
</template>

<script>
export default {
  name: "FileField",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    isDoc: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    accept: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {},
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
